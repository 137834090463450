.addUser {
    &.healthyWorking.questions { max-width:1500px; max-height:95%; display:flex; flex-direction:column; }

    form { display:flex; flex-direction:column; justify-content:flex-start; max-height:100%; overflow:hidden;

        .tabs { display:flex; flex-direction:row; list-style-type:none; margin:10px 0 20px; padding:0;
            li { display:block; flex:1; text-align:center; line-height:2rem; position:relative; cursor:pointer; border-bottom:4px solid #ccc; text-transform:uppercase;
                &:before { content:''; position:absolute; bottom:-4px; left:50%; width:0; height:4px; border-bottom:4px solid #ccc; transform:translateX(-50%); transition:all 200ms; opacity:0; }

                &:hover {
                    &:before { width:100%; opacity:1; }
                }

                &.current { font-weight:bold; }
            }
        }

        input.sessionName { text-align:left !important; }

        div.categories { margin:10px 0; flex:1;
            .category {
                &--enabled { padding-bottom:5px; margin-bottom:5px; }

                &__head, &__activateAll { display:flex; flex-direction:row; justify-content:flex-start; align-items:stretch; align-content:stretch; background:#f2f2f2; border-radius:5px; margin-bottom:5px; padding:5px;

                    &__enable { margin:auto 0; }

                    &__name { flex:1; border-radius:5px; text-align:left; margin:0 10px; background:#fff;
                        &--disabled { color:#ccc; background:#f2f2f2; border-color:#f2f2f2; cursor:auto; }
                        &--enabled { background:#f2f2f2; border-color:transparent; cursor:auto; }

                        &--readonly { background:none; border:1px solid transparent; }
                        &:hover:not(.category__head__name--disabled) { border:1px dashed #191919; }
                        &:focus:not(.category__head__name--disabled) { background:#fff; border:1px solid #191919; }
                    }

                    &__remove { line-height:2rem; cursor:pointer; border-radius:5px; transition:all 200ms;
                        &:hover { background:#ccc; color:#fff; }

                        &--disabled { color:#f2f2f2 !important; background:#f2f2f2 !important; cursor:default; }
                    }
                }

                &__activateAll { background:none;
                    &__text { margin-left:21px; cursor:pointer; }
                }

            }
        }

    }
}

