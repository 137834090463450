.passwordForm { position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); background:#fff; box-shadow:0 0 10px 0 rgba(0,0,0,.3); border-radius:6px; overflow:hidden; max-width:500px; width:100%;
    form { padding:30px 40px; box-sizing:border-box;

        .passwordText { margin:0 0 20px; }

        .box { margin:0 0 20px;
            .passwordInput { display:flex; flex-direction:row;
                input { width:100%; border:1px solid #ccc; box-sizing:border-box; line-height:2rem; text-align:center; padding:0 10px; text-overflow:ellipsis; border-radius:20px; font-size:1rem; }
                .showPassword { margin-left:10px; cursor:pointer; line-height:2rem; color:#464646; }
            }

            .bar { margin-right:35px;
                > div { height:5px; margin:5px 10px 0 !important;
                    div { height:5px !important; transition:all 200ms }
                }
                p { color:#aaa !important; margin:5px 10px 0 !important; line-height:1rem; }
            }

            .passwordRepeat { border-top:5px solid #ddd; font-size:14px; font-style:italic; text-align:right; margin:5px 10px; padding-top:5px; box-sizing:border-box; color:#aaa; transition:all 200ms; margin-right:45px;
                &.notSame { border-color:#ef4836; }
                &.same { border-color:#25c281; }
            }
        }

        .error { text-align:center; color:#ef4836; margin-bottom:20px; font-weight:bold; }
    }

    .passwordSet { padding:30px 40px; box-sizing:border-box; text-align:center; }
}
