.chatBox { max-width:1500px; width:100%; height:100%; max-height:100%; margin:0 auto; overflow:hidden; justify-content:flex-start; align-items:stretch; align-content:stretch; display:flex; flex-direction:column;

    h3 { height:2.25rem; }

    .chatBoxInner { display:flex; flex-direction:row; overflow:hidden; align-items:stretch; align-content:stretch; flex:1; max-height:100%; height:100%;
        .left { flex:1; max-height:300px; height:100%; order:1; margin-right:30px;
            .chatBoxInput { position:relative; margin-top:10px; box-sizing:border-box;
                input { border:1px solid #ccc; width:100%; height:3rem; line-height:3rem; border-radius:5px; text-align:left; padding:0 40px 0 10px; box-sizing:border-box;
                    &::-webkit-input-placeholder { text-align:left; }
                    &:-moz-placeholder { text-align:left; } /* FF 4-18 */
                    &::-moz-placeholder { text-align:left; } /* FF 19+ */
                    &:-ms-input-placeholder { text-align:left; } /* IE 10+ */

                    &[value=''] + .icon-up { opacity:0; }
                }
                .icon-up { position:absolute; right:5px; top:50%; transform:translateY(-50%); font-size:1.2rem; border-radius:100%; width:30px; height:30px; line-height:30px; text-align:center; background:#6b9e0b; color:#fff; transition:all 200ms; }
            }
        }

        .right { flex:1; max-height:150px; height:100%; overflow:auto; order:2; margin-left:30px;
            .chatMessage { line-height:1.5rem;
                .time { width:70px; display:inline-block; }
            }
        }
    }

}

@media (max-width:800px) {
    .chatBox {
        h3 { display:none; }
        .chatBoxInner { display:flex; flex-direction:column; justify-content:flex-start; align-items:stretch; align-content:stretch; max-height:100%; height:100%;
            .left { order:2; margin:0; flex:none; height:auto; }
            .right { order:1; margin:0; flex-direction:row;
                justify-content:flex-start;
                align-items:stretch;
                align-content:stretch; flex:1; max-height:none; }
        }
    }
}
