@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

.results { padding:10px; display:flex; flex-direction:column; height:100%; box-sizing:border-box; justify-content:flex-start; align-items:stretch; align-content:stretch;

    .subheadline { text-align:center; margin:-20px 0 20px; font-style:italic; white-space:pre-line;
        p { margin-bottom:0; }
    }

    .form { max-width:80%; width:100%; margin:0 auto; flex:1 0 auto; display:flex; flex-direction:column; justify-content:center;

        .choose { margin-bottom:40px;
            .question { margin-right:100px; }
            .answer { margin-right:50px; text-transform:uppercase; font-weight:normal;
                input { margin-right:10px; }
            }
        }

        label { font-weight:bold; font-style:italic; }

        textarea { margin:10px 0 40px; background-attachment:local; border:0; border-radius:0; line-height:31px; padding:0 30px; width:100%; box-sizing:border-box; resize:none; white-space:pre-wrap;
            background-image:
                    linear-gradient(to right, white 0, transparent 0),
                    linear-gradient(to left, white 0, transparent 0),
                    repeating-linear-gradient(white, white 30px, #ccc 30px, #ccc 31px, white 31px);
        }

        .textBox { display:flex; flex-direction:row; justify-content:center;
            .allowDownload { display:flex; flex-direction:row; justify-content:center; line-height:20px; cursor:pointer; margin:10px auto 15px;
                input:checked + svg {
                    .tick { opacity:1; stroke-dashoffset:0; }
                }

                input { display:none; }

                svg { width:20px; height:20px; margin-right:10px;
                    .circ { stroke:#191919; }
                    .tick { fill:none; stroke:#191919; stroke-dasharray:48; stroke-dashoffset:48; transition:stroke-dashoffset 200ms ease-out; }
                }
            }

            label { display:block; line-height:20px; margin:10px auto 15px; flex:1 0 auto; box-sizing:border-box; text-align:center; }
        }

        hr { border-color:transparent; border-top:1px solid #ccc; margin:40px auto; width:100%; }

        .shortinfo { max-width:600px; text-align:center; margin:0 auto 30px; font-style:italic; }

        .contact { text-align:center; margin-bottom:20px; font-style:italic;
            strong { font-size:2rem; margin-top:5px; display:block;
                a { color:#191919; text-decoration:none; }
            }
        }

        .downloadList { display:flex; flex-direction:column; justify-content:center;
            .box { flex:1 0 auto; padding:0 5px 0; margin:0 auto; box-sizing:border-box; max-width:500px; width:100%;

                label { display:block; line-height:20px; margin:10px auto 15px; flex:1 0 auto; box-sizing:border-box; text-align:center; }

                .inner { display:flex; flex-direction:row; justify-content:center; }

                input { flex:1 0 auto; border:1px solid #ccc; border-radius:20px; margin-right:10px; text-align:center; }
                button { border:0; border-radius:20px; color:#fff; cursor:pointer; padding:0 20px; line-height:2.5rem; text-transform:uppercase; display:flex; justify-content:center; margin:0 auto;
                    &.done { background:#6b9e0b; }
                    &:disabled { background:#ccc; }
                }

                .error { margin-top:20px; color:#a60000; text-align:center; white-space:pre-line; }
                .generate { width:100%; }
            }
        }

        .loading { position:relative; display:flex; flex-direction:column; justify-content:center; margin-left:10px;
            .spinner { margin:auto;
                div { width:5px; height:5px; background-color:#fff; border-radius:100%; display:inline-block; animation:sk-bouncedelay 1.4s infinite ease-in-out both;
                    &.bounce1 { animation-delay:-0.32s; }
                    &.bounce2 { animation-delay:-0.16s; }
                    &.bounce3 { animation-delay:-0.12s; }
                }
            }
        }

    }
}


@media (max-width:800px) {
    .results { height:auto; }
}
