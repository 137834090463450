#root .switcher { display:none; }

.app { display:flex; flex-direction:row; width:100%; height:100%;
    &.blur { filter:blur(5px); }
    &.left {
        .game { order:1; }
        .forms { order:2; border:0; border-left:2px dashed #ccc;
            .menu {
                ul { left:auto; right:0; transform:none; }
            }
        }
        .zoom { right:auto; left:0; border-radius:0 5px 0 0; }
    }
    .forms { border-right:2px dashed #ccc; box-sizing:border-box; }

    .zoom { position:fixed; bottom:0; right:0; width:30px; height:30px; padding:10px; background:#fff; border-radius:5px 0 0 0; box-shadow:0 0 10px 0 rgba(0,0,0,.3); cursor:pointer; transition:all 200ms; z-index:100; }

    .game { width:calc(100vh - 80px); height:100%; margin:0 auto; padding:10px; box-sizing:border-box; max-width:50%; display:flex; flex-direction:column; justify-content:center;
        .gameBox { max-width:100%; max-height:100%; overflow:hidden; display:flex; flex-direction:column; justify-content:center; border:10px solid #fff; box-shadow:0 0 20px rgba(0,0,0, .3); box-sizing:border-box; }
    }
}

@media (max-width:1440px) {
    .app {
        .forms { width:60%; }
        .game { max-width:40%; }
    }
}

@media (max-width:1280px) {
    #root { display:flex; flex-direction:column; justify-content:flex-start; align-items:flex-start; align-content:flex-start; overflow:hidden; height:100%; }
    #root .switcher { display:flex; flex-direction:row; list-style-type:none; margin:0; padding:0; line-height:3rem; border-bottom:2px solid #ccc; box-sizing:border-box; width:100%;
        li { width:50%; text-align:center; font-size:1rem; text-transform:uppercase; position:relative; font-family:'Merriweather Sans', serif; font-weight:bold; cursor:pointer; color:#333;
            &.current:before { content:''; position:absolute; bottom:-2px; left:0; right:0; width:100%; border-bottom:2px solid #ccc; }
        }
    }
    .app { flex-direction:column; flex:1; width:100%; margin:auto; overflow:hidden;
        //&.game { display:none; order:1; max-width:96%; width:90vh; }

        .zoom { display:none; }

        &.form {
            .forms{ display:flex; width: 100%; border:0; }
            .game { display:none; }
        }

        &.game {
            .forms { display:none; }
            .game { display:flex; max-width:none; }
        }
    }
}
