.lobbyContainer { display:flex; flex-direction:column; justify-content:flex-start; align-items:stretch; align-content:stretch; height:100%;
    .lobby { flex:1; width:100%; height:100%; position:relative; display:flex; flex-direction:column; overflow:hidden; align-items:stretch; align-content:stretch;
        .connection { padding:5px 10px; margin:0 auto; line-height:1.5rem; display:flex; flex-direction:row; justify-content:center; white-space:pre;
            b { display:inline-block; width:110px; }
        }

        .waitingLayer { position:absolute; top:0; right:0; bottom:0; left:0; background:rgba(0,0,0,.5); z-index:20;
            .waiting { position:absolute; top:50%; left:50%; text-align:center; transform:translate(-50%, -50%); background:#fff; padding:20px; box-sizing:border-box; border-radius:10px; max-width:500px; width:100%;
                h1 { font-size:1.5rem; line-height:2.5rem; }
                h3 { font-size:1rem; line-height:2rem; text-transform:none; }

                p { font-size:1rem; line-height:1.5rem; margin:10px 0; font-style:italic; }

                .selection { display:flex; flex-direction:row; margin:0 auto; justify-content:center;
                    .option { line-height:2rem; }
                }

                button { text-transform:uppercase; }
            }
        }

        #btnStart { max-width:200px; margin:20px auto; }
    }

    .lobbyFooter {
        .footerInner {
            .logoBox { width:300px; flex:1; }

            .centerBox { display:flex; flex-direction:row; justify-content:center; flex:1;
                .footer_button { display:block; width:200px; margin:auto 5px; text-decoration:none; text-align:center; }
            }

            .linkBox { display:flex; flex-direction:row; flex:1; justify-content:flex-end;
                .footer_button { display:block; width:150px; color:#fff; text-align:center; margin:auto 0 auto 10px;
                    &.reset { border-color:#c51a1a; color:#c51a1a;
                        &:hover { background:#c51a1a; color:#fff; }
                    }
                }
            }
        }
    }
}

@media (max-width:1500px) {
    .lobbyContainer .lobby { padding:10px; box-sizing:border-box;
    }
}

@media (max-width:1280px) {
    .lobbyContainer {
        .lobby {
            //.connection { position:relative; margin:0 auto; padding:0 0 10px; text-align:center; top:0; left:0; box-sizing:border-box; width:100%; display:flex; flex-direction:row; justify-content:flex-start;
            //    div { margin-right:10px; }
            //}
        }
    }
}

@media (max-width:800px) {
    .lobbyContainer {
        .lobby { flex:1; justify-content:flex-start; align-items:stretch; align-content:stretch; overflow:auto; display:flex; flex-direction:column; height:100%;
            .connection { padding:0 0 10px;
                b { width:100px; }
            }

            .waitingLayer {
                .waiting {
                    .selection { flex-direction:column;
                        .option { margin:10px auto 5px; }
                    }
                }
            }
        }

        .lobbyFooter {
            .footerInner { padding:15px 10px;
                .logoBox { height:20px;
                    .logo { height:20px; }
                }


                .centerBox {
                    .button { width:150px; }
                }

                .linkBox {
                    .button { width:150px; }
                }
            }
        }
    }
}


@media (max-width:600px) {
    .lobbyContainer .lobby {
        .connection { }
        .waitingLayer .waiting { max-width:90vw;
        }

    }
}
