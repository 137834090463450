.filter { display:flex; flex-direction:row; justify-content:center; flex-grow:1; max-width:1500px; width:100%; margin:30px auto 0;
    div { flex:4; text-align:center; border-bottom:2px solid #ccc; line-height:2.5rem; text-transform:uppercase;
        &.number { flex:1; }
        &.date { flex:2; }
        &.time { flex:2; }

        &.name { flex:3; }
        &.mail { flex:3; }

        &.state { flex:2; }
        &.video { flex:none; width:40px; display:flex; justify-content:center;
            span { margin:auto 0; }
        }
        &.board { flex:none; width:60px; }
        &.pdf { flex:none; width:60px; }
        &.options { flex:none; width:50px; }
    }
}
