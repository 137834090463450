.addUser { max-width:600px; width:100%; }
.healthyWorking.addUser { max-width:600px; width:100%; height:auto;
    &.questions { max-width:1000px; }

    .addHealthyWorkingSession { overflow:auto;

        h5 { display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; }

        .box { margin-top:10px; width:100%; grid-template-columns:1fr;
            &.sessionData { grid-template-columns:1fr 1fr; grid-column-gap:10px; }

            &.sessionLogoColor { grid-template-columns:1fr 1fr; grid-column-gap:10px;
                .flex { display:flex; flex-direction:column; justify-content:center;
                    .logo { flex:auto; display:block; max-width:140px; max-height:140px; width:100%; height:auto; overflow:hidden; position:relative; margin:auto; border-radius:100%; border:3px solid #ccc; padding:5px; box-shadow:0 0 10px rgba(0,0,0,.2); box-sizing:border-box; cursor:pointer;
                        &:before { content:''; float:left; padding-top:100%; }

                        img { position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); max-width:95%; max-height:95%; }
                        input { display:none; }
                    }

                    .color { display:flex;
                        svg { margin:auto; }
                    }
                }
            }


            &.dateTime { grid-template-columns:1fr 1fr 1fr 1fr; grid-column-gap:10px;
                input { margin:0; width:150px }
                .rc-time-picker { width:auto;
                    input { width:100%; }
                }
            }
        }

        .buttonBox { max-width:500px; width:100%; margin:0 auto; }
    }
}
