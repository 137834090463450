.vouchers {
    .tabs { display:flex; flex-direction:row; list-style-type:none; margin:10px 0 0; padding:0;
        li { display:block; flex:1; text-align:center; line-height:2rem; position:relative; cursor:pointer; border-bottom:4px solid #ccc;
            &:before { content:''; position:absolute; bottom:-4px; left:50%; width:0; height:4px; border-bottom:4px solid #ccc; transform:translateX(-50%); transition:all 200ms; opacity:0; }

            &:hover {
                &:before { width:100%; opacity:1; }
            }

            &.current { font-weight:bold; }
        }
    }

    .list { list-style-type:none; margin:20px 0; padding:0;
        li { line-height:1.5rem;
            input { border:1px solid #ccc; border-radius:5px; padding:5px 10px; margin-bottom:10px; width:100%; box-sizing:border-box; }
        }
    }


    .buttonBox { display:grid; grid-template-columns:1fr 1fr; column-gap:10px;
        button { width:100%; }
    }
}
