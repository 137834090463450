.lobbyBoxOuter { display:flex; flex-direction:column; justify-content:center; height:100%; overflow:hidden; align-items:stretch; align-content:stretch;
    .lobbyBox { margin:auto; width:100%; max-height:100%; height:100%; display:grid; grid-template-rows:6fr 15px 3fr; grid-row-gap:20px; overflow:hidden; box-sizing:border-box;
        &.fullscreen {
            .videoArea { position:fixed; top:0; right:0; bottom:0; left:0; height:100%; max-width:100%; width:100%; grid-template-columns:1fr 200px; z-index:100; overflow:hidden; background:#323232;
                .videoBox { overflow:hidden;
                    &.local { order:2; }
                    &.remote { order:1; }
                    .options { position:fixed; bottom:0; left:50%; transform:translateX(-50%); background:#fff; border-radius:10px 10px 0 0; z-index:200; margin:0; padding:10px 5px;
                        &.remote { display:none; }
                    }
                    .video { display:flex; flex-direction:row; justify-content:center;
                        video { border:0 !important; border-radius:0 !important; height:100%; width:auto;
                            &#vidLocal { width:200px; height:auto; min-height:auto; }
                        }
                    }
                }
            }

            .horizontalShadow,
            .chatBox { display:none; }

        }

        .videoArea { display:grid; grid-template-columns:1fr 1fr; grid-template-rows:1fr; gap:0 60px; grid-auto-rows:1fr; max-width:1500px; width:100%; max-height:100%; margin:auto; z-index:10; overflow:hidden;

            .videoBox { display:flex; flex-direction:column; height:100%; max-height:100%; overflow:hidden; justify-content:flex-start; align-items:stretch; align-content:stretch;
                .options { display:flex; flex-direction:row; justify-content:space-between; line-height:3rem; height:3rem; margin-bottom:10px;
                    .name { font-size:2rem; }
                    .buttons { display:flex;
                        div { cursor:pointer; height:3rem; line-height:3rem; width:3rem; font-size:2.5rem; margin-left:5px; text-align:center; border-radius:6px; transition:all 200ms;
                            &:hover { background:#ccc; }
                        }
                    }

                    &.remote { justify-content:flex-end; }
                }

                .video { flex:1; width:100%; height:100%; position:relative; overflow:hidden;
                    video { background:#323232; width:100%; height:100%; max-height:100%; border:10px solid #fff; border-radius:10px; box-sizing:border-box; position:relative; }
                    .videoOverlay { position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); color:#fff; font-size:10rem; }
                }
            }
        }

        .buttonBox { margin:30px auto; display:flex; flex-direction:row; justify-content:center; max-width:1500px; width:100%;
            .button { display:flex; flex-direction:row; justify-content:center; width:200px; margin:0 10px;
                .material-icons { margin-right:5px; font-size:1.5rem; }
                span { display:inline-block; line-height:2rem; font-size:1rem; }
            }
        }

        .horizontalShadow { margin:0 auto; height:15px;
            &:before { background-size:70% 10px; }
        }

    }
}


@media (max-width:1280px) {
    .lobbyBox { padding:0; box-sizing:border-box;
        .videoArea { grid-template-rows:35vw;
            .videoBox {
                .video { overflow:hidden; }
            }
        }
    }
}

@media (max-width:800px) {
    .lobbyBoxOuter {
        .lobbyBox { flex:1; margin:0; overflow:auto; height:100%;
            .videoArea { display:flex; flex-direction:column; grid-template-rows:50% 50%; overflow:hidden; max-height:100%; margin:0;
                .videoBox { height:100%; display:flex; overflow:hidden; justify-content:flex-start; align-items:stretch; align-content:stretch;
                    .options { margin:0;
                        &.remote { justify-content:flex-start; }
                        .name { font-size:1.5rem; }

                        .fullscreen { display:none; }
                    }

                    .video { flex:1; max-height:100%; overflow:hidden;
                        video { border-width:5px; min-width:100px; width:100%; margin:0 auto; left:50%; transform:translateX(-50%); }
                    }
                }
            }

            .horizontalShadow { margin:auto; }
        }
    }
}
