.addUser {
    form {
        div.categories { max-height:700px; overflow:auto;
            .category {
                &__questions__new { display:flex; flex-direction:row; justify-content:center; border-radius:5px; margin-bottom:5px; padding:5px;
                    &__add { border-radius:5px; padding:5px 10px; transition:all 200ms; background:#fff; cursor:pointer; border:1px solid #ccc; }
                }
            }
        }
    }
}

