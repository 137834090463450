.popup { padding:20px 30px; max-width:500px; width:100%; box-sizing:border-box;
    .close { fill:#000; width:25px; height:25px; position:absolute; top:5px; right:5px; cursor:pointer; padding:10px; }

    .error { color:#a60000; margin-bottom:20px; }

    .buttonBox { margin:0 0 20px; display:grid; grid-template-columns:1fr 1fr; grid-gap:10px;
        .button { width:100%; margin:0; }
    }

}
