.sideMenuButton { position:fixed; top:5px; right:20px; height:60px; display:flex; flex-direction:row; cursor:pointer;
    .username { line-height:50px; white-space:nowrap; margin-right:20px; }
    .userpic { position:relative; width:50px; height:50px;
        .userpicInner { width:100%; height:100%; display:flex; flex-direction:column; justify-content:center; background:#fff; border-radius:100%; box-sizing:border-box; border:3px solid transparent; box-shadow:0 0 10px rgba(0,0,0,.2); padding:4px; overflow:hidden;
            img { max-width:100%; max-height:100%; }
        }
    }
}

.sideMenu { font-size:1rem; z-index:20;
    &.visible {
        .overlay { width:100%; background:rgba(0,0,0,.5); }
        .menuInner { right:0; }
    }

    .overlay { position:fixed; width:0; top:0; right:0; bottom:0; z-index:100; transition:background 200ms; background:rgba(255,255,255,0); cursor:pointer; }

    .menuInner { position:fixed; top:0; right:-300px; bottom:0; height:100%; width:300px; background:#fff; z-index:110; box-shadow:0 0 10px 0 rgba(0,0,0,.5); transition:all 200ms; display:flex; flex-direction:column; align-items:stretch; align-content:stretch;
        .userpic { position:relative; width:100%; padding-top:200px; background:#ddd;
            .userpicInner { position:absolute; top:50%; right:0; bottom:0; left:50%; width:50%; height:75%; transform:translate(-50%, -50%); display:flex; flex-direction:column; justify-content:center; background:#fff; border-radius:100%; padding:10px; box-sizing:border-box; border:3px solid transparent; overflow:hidden; cursor:pointer;
                img { max-width:100%; max-height:100%; }
            }
        }
        .userBox { background:#ddd; text-align:center; padding:5px 20px; line-height:2rem;
            .username { font-weight:bold; text-overflow:ellipsis; white-space:nowrap; overflow:hidden; }
        }


        .navigation { list-style-type:none; padding:0; margin:20px 0; flex:1;
            li { line-height:2rem; padding:5px 0;
                hr { margin:0 20px; border:0; border-top:1px solid #aaa; }
                a { text-decoration:none; color:#323232; padding:0 30px; box-sizing:border-box; display:block; white-space:nowrap; }
            }
        }

        .logoff { line-height:2rem; padding:10px 8px 5px; text-decoration:none; color:#323232; box-sizing:border-box; display:block; white-space:nowrap; border-top:1px solid #aaa; margin:0 20px 10px; text-align:right; }

    }
}
