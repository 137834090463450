.healthyWorking {
    .topBox { position:relative; max-width:80%; width:100%; margin:0 auto;

        .section { width:100%; text-align:center; font-size:2rem; line-height:3rem; margin:20px auto 30px; padding:5px 0; border-bottom:2px solid #ccc; text-transform:uppercase; }

        .languageSwitch { position:absolute; top:50%; left:0; display:flex; flex-direction:row; transform:translateY(-50%); z-index:1;
            .lang { font-family:'Merriweather Sans', serif !important; color:#fff; font-size:.9rem; padding:5px 10px; margin-right:5px; background:#ccc; border-radius:5px; transition:all 200ms; cursor:pointer; }
        }

        .users { position:absolute; top:50%; right:0; display:flex; flex-direction:row; transform:translateY(-50%); z-index:1;

            .name { line-height:25px; font-family:'Merriweather Sans', serif !important; margin-right:10px; color:#ccc; font-size:.9rem;
                &.active { color:#191919; }
            }

            .status { width:25px; height:25px;
                path { fill:#a60000; }

                &.active {
                    path {
                        fill:#6b9e0b;
                    }
                }
            }
            .user {  }
            .admin { margin:0 10px 0 5px; }
        }
    }
}

@media (max-width:1024px) {
    .healthyWorking {
        .topBox { max-width:none; padding:0; box-sizing:border-box; position:relative;
            .section { font-size:1.5rem; margin:0 0 20px; }
        }
    }
}
