.healthyWorking {
    .startContainer { padding:10px; height:100%; display:flex; flex-direction:column; justify-content:flex-start; align-items:stretch; align-content:stretch; box-sizing:border-box;
        .subheadline { text-align:center; margin:-20px 0 20px; font-style:italic; white-space:pre-line;
            p { margin-bottom:0; }
        }

        .start { display:flex; flex-direction:column; justify-content:center; padding:0 15px; flex:1 1 auto; max-width:1000px; width:100%;  margin:0 auto;
            .sections { display:flex; flex-direction:row;
                section { flex:1 1 auto; padding:0 15px; line-height:1.5rem;
                    p {  white-space:pre-line; margin:0 0 15px; }
                }
            }
        }
    }
}

@media (max-width:1024px) {
    .healthyWorking {
        .startContainer {
            .start { padding:0;
                ul { padding:0 0 0 10px; }
            }
        }
    }
}
