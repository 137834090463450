@keyframes pulse {
    0% { box-shadow:0 0 0; }

}

.listItem { display:flex; flex-direction:row; justify-content:center; flex-grow:1; max-width:1500px; width:100%; margin:0 auto; padding:10px 0; box-sizing:border-box; border-bottom:2px solid #ccc;
    div { flex:4; text-align:center; line-height:2rem;
        &.number { flex:1; }
        &.date { flex:2; }
        &.time { flex:2; }

        &.name,
        &.mail { flex:3; text-overflow:ellipsis; overflow:hidden; }

        &.state { flex:2;
            button, .button { border:1px solid #ccc; padding:0 10px; border-radius:20px; box-sizing:border-box; width:100%; line-height:2rem; text-decoration:none;
                &.new { background:#f0f0f0; }
                &.waiting { animation:pulse 1000ms forwards infinite alternate-reverse; }
            }
        }

        &.video { flex:none; width:40px; display:flex; justify-content:center;
            span { margin:auto 0; }
        }
        &.board { flex:none; width:60px; display:flex; justify-content:center;
            span { margin:auto 0; }
            .icon--rotated { transform:rotate(90deg); }
        }

        &.pdf { flex:none; width:60px;
            .generate {cursor:pointer; }
            .loading { position:relative; display:flex; flex-direction:column; justify-content:center;
                .spinner { margin:auto;
                    div { width:5px; height:5px; background-color:#333; border-radius:100%; display:inline-block; animation:sk-bouncedelay 1.4s infinite ease-in-out both;
                        &.bounce1 { animation-delay:-0.32s; }
                        &.bounce2 { animation-delay:-0.16s; }
                        &.bounce3 { animation-delay:-0.12s; }
                    }
                }
            }

            #react-qrcode-logo { display:none; }
        }

        &.options { position:relative; cursor:pointer; flex:none; width:50px; display:flex; flex-direction:column; justify-content:center;
            &:hover {
                svg { background:#ddd; border:1px solid #ccc; transition:all 200ms; }
            }
            svg { width:1rem; height:1rem; line-height:1rem; padding:5px 5px; border-radius:20px; margin:auto; border:1px solid transparent;
                path {fill:#191919; }
            }
            &:hover ul { display:block; }
            ul { display:none; position:absolute; top:100%; left:50%; transform:translateX(-50%); list-style:none; background:#fff; margin:0; padding:0; border-radius:5px; box-shadow:0 0 5px rgba(0,0,0,.5); z-index:10;
                li { text-align:center; white-space:nowrap; padding:5px 10px; transition:all 200ms;
                    &:not(:last-child) { border-bottom:1px solid #ddd; }
                    &:hover { color:#fff; }
                }
            }
        }
    }
}
.listItemEmpty { text-align:center; padding:10px 0; box-sizing:border-box; max-width:1500px; width:100%; margin:0 auto; border-bottom:2px solid #ccc; }
