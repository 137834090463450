.addUser {
    form {
        div.categories {
            .category {
                &__category__new { display:flex; flex-direction:row; justify-content:center; background:#f2f2f2; border-radius:5px; margin-bottom:5px; padding:5px; transition:all 200ms; cursor:pointer;
                    &__add { border-radius:5px; background:#fff; padding:5px 10px; transition:all 200ms; }

                    &:hover { background:#fff;
                        .category__category__new__add { background:#191919; color:#fff; }
                    }
                }
            }
        }
    }
}

