.editProfile { padding:20px 30px; max-width:600px; width:100%; box-sizing:border-box;
    .close { fill:#000; width:25px; height:25px; position:absolute; top:5px; right:5px; cursor:pointer; padding:10px; }

    ul { display:flex;; flex-direction:row; list-style-type:none; margin:0 20px 0 0; padding:0; line-height:2rem;
        li { width:50%; text-align:center; position:relative; transition:all 200ms; cursor:pointer;
            &:before { content:''; display:block; position:absolute; bottom:-1px; left:50%; width:0; transform:translateX(-50%); border-bottom:2px solid #fff; z-index:2; transition:all 200ms; }

            &:hover:before { width:100%; }
            &.current:before { width:100%; }
        }
    }

    .horizontalShadow { margin:0 0 30px;
        &:before { height:5px; background-size:90% 5px; }
    }

    .box { display:grid; grid-template-columns:1fr 1fr; column-gap:20px; margin-bottom:20px;
        .flex { flex:1;
            label { display:block; line-height:1rem; margin-bottom:10px; padding:0 10px; font-weight:bold; }
            input { border:1px solid #ccc; line-height:2rem; width:100%; border-radius:15px; padding:0 10px; box-sizing:border-box;
                &:read-only { color:#ccc; cursor:text; }
                &#website { text-align:left;
                    &::-webkit-input-placeholder,
                    &::-webkit-input-placeholder { text-align:left; }

                    &:-moz-placeholder,
                    &:-moz-placeholder { text-align:left; } /* FF 4-18 */

                    &::-moz-placeholder,
                    &::-moz-placeholder { text-align:left; } /* FF 19+ */

                    &:-ms-input-placeholder,
                    &:-ms-input-placeholder { text-align:left; } /* IE 10+ */
                }
            }

            .logo { flex:auto; display:block; max-width:150px; width:100%; height:auto; overflow:hidden; position:relative; margin:0 auto; border-radius:100%; border:3px solid #ccc; padding:5px; box-shadow:0 0 10px rgba(0,0,0,.2); box-sizing:border-box; cursor:pointer;
                &:before { content:''; float:left; padding-top:100%; }

                img { position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); max-width:95%; max-height:95%; }
                input { display:none; }
            }

            .bar {
                > div { height:5px; margin:5px 10px 0 !important;
                    div { height:5px !important; transition:all 200ms }
                }
                p { color:#aaa !important; margin:5px 10px 0 !important; line-height:1rem; }
            }

            .passwordRepeat { border-top:5px solid #ddd; font-size:14px; font-style:italic; text-align:right; margin:5px 10px 0; padding-top:5px; box-sizing:border-box; color:#aaa; transition:all 200ms;
                &.notSame { border-color:#ef4836; }
                &.same { border-color:#25c281; }
            }
        }

        .color { width:160px; height:160px; margin:0 auto;
            .react-colorful { width:150px; height:150px; }
            .react-colorful__hue { height:20px; }
        }
    }

    .userData,
    .changePassword {
        .buttonBox { display:block;
            button { margin:0 auto; width:50%; }
        }
    }

    .hint { font-style:italic; padding:0 10px; margin-bottom:20px; line-height:1.5rem; text-align:center; }
}
