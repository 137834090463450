.addUser { padding:20px 30px; max-height:90%; box-sizing:border-box; overflow:auto;
    .close { fill:#000; width:20px; height:20px; position:absolute; top:15px; right:5px; cursor:pointer; padding:10px; }

    form {
        h3 { margin-bottom:20px; }

        input:not([type=checkbox]):not([type=radio]) { width:100%; border:1px solid #ccc; box-sizing:border-box; line-height:2rem; text-align:center; padding:0 10px; text-overflow:ellipsis; border-radius:20px; font-size:1rem; }

        .box { margin:0 0 20px; display:grid; grid-template-columns:1fr 1fr; grid-gap:10px;
            &.voucherCount { grid-template-columns:60px 1fr; grid-gap:5px; line-height:2rem;
                input { max-width:60px; text-align:center;
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                    }
                    -moz-appearance: textfield;
                }
            }
        }

        .model { margin:10px 0 10px; display:flex;  flex-direction:row; flex-wrap:wrap;
            label { display:inline-block; flex:1; line-height:2rem; margin:0 auto 0 0; cursor:pointer; }
        }

        .expirationDate { margin:10px 0 10px;
            label { display:inline-block; line-height:2rem; cursor:pointer;
                &.disabled { color:#ccc;
                    input { color:#ccc; }
                }
            }

            .inputlabel { display:flex; line-height:2rem; cursor:pointer; margin-top:20px;
                input { margin:0 10px; max-width:150px; }
            }
        }

        .branding { margin:10px 0 10px;
            label { display:inline-block; line-height:2rem; cursor:pointer; }
        }
        .selfCoach { margin:10px 0 10px;
            label { display:inline-block; line-height:2rem; cursor:pointer; }
        }

        .dateTime { margin:10px 0 20px; grid-template-columns:33% 25% 1fr;
            .rc-time-picker {
                .rc-time-picker-input { display:block; height:32px; box-sizing:border-box; }
                .rc-time-picker-clear { display:none !important; }
            }
        }

        .settings { margin:10px 0 10px; display:flex; flex-direction:column;
            label { display:inline-block; line-height:2rem; cursor:pointer; }
        }

        .boardType { margin:10px 0 10px; display:flex; flex-direction:row;
            label { display:inline-block; line-height:2rem; cursor:pointer; }
        }

        .error { text-align:center; color:#a60000; margin:20px 0; }

        .buttonBox { margin:20px 0 0; display:grid; grid-template-columns:1fr 1fr; grid-gap:10px;
            .button { width:100%; margin:0; }
        }

    }
}

.rc-time-picker-panel-input { text-align:center; }
