.dashboard { padding-top:80px;
    .topBar { position:fixed; top:0; right:0; left:0; background:#fff; box-shadow:0 10px 10px rgba(0,0,0,.1); height:60px; line-height:50px; display:flex; flex-direction:row; padding:5px 10px; box-sizing:border-box; font-size:1.5rem; z-index:10;


        .centerBox { margin:0 auto; display:flex; flex-direction:row;
            h2 { font-size:1.5rem; line-height:50px; }
            h3 {  font-size:1.5rem; line-height:50px; }
        }
    }

    .links { display:flex; flex-direction:row; max-width:1500px; width:100%; margin:0 auto; line-height:2rem; padding-bottom:10px;
        a { width:50%; text-align:center; text-decoration:none; color:#646464; position:relative; text-transform:uppercase;
            &:before { content:''; display:block; position:absolute; bottom:-14px; left:50%; width:0; transform:translateX(-50%); border-bottom:4px solid #fff; z-index:2; transition:all 200ms; }

            &:hover:before { width:100%; }
            &.current { font-weight:bold;
                &:before { width:100%; }
            }
        }
    }

    .buttonBox { display:flex; flex-direction:row; max-width:1500px; width:100%; margin:0 auto;
        button { margin:10px; width:100%; max-width:200px;
            &.button--big { max-width:260px; }
        }
    }
}

.adminFooter {
    .linkBox { display:flex; flex-direction:column;
        .button { width:auto; padding:0 20px; margin:auto; }
    }
}

@media (max-width:1100px) {
    .dashboard {
        .topBar .centerBox { margin:0; }
    }
}
