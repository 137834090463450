@import "font.scss";
@import "text.scss";

html { font-size:14px; }
body, html { height:100%; zoom:reset; }
body { background:#fff; font:normal 14px/1.4em; color:#191919; position:relative; overflow-x:hidden;
    &.noscroll { overflow:hidden; }
}

input, textarea { font:normal 14px/1.4em; color:#191919; -webkit-appearance:none; }
:-webkit-autofill { font:normal 14px/1.4em; color:#191919; -webkit-appearance:none; }

input { box-shadow: none !important; }
:-webkit-autofill { color: #fff !important; }
textarea:focus, input:focus{
    outline: none;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */


textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder { color: #c8c8c8; text-align:center; }

textarea:-moz-placeholder,
input:-moz-placeholder { color:#c8c8c8; text-align:center; } /* FF 4-18 */

textarea::-moz-placeholder,
input::-moz-placeholder { color:#c8c8c8; text-align:center; } /* FF 19+ */

textarea:-ms-input-placeholder,
input:-ms-input-placeholder { color:#c8c8c8; text-align:center; } /* IE 10+ */

input[type='radio'] { border:2px solid #ccc; border-radius:100%; width:16px; height:16px; position:relative; -webkit-appearance:none; cursor:pointer; display:inline-block; margin:auto 10px; transition:all 200ms;
    &:checked:before { content:''; position:absolute; top:2px; right:2px; bottom:2px; left:2px; border-radius:100%; background:#646464 }
    &:checked { border-color:#646464 }
}
input[type='checkbox'] { border:2px solid #ccc; border-radius:8px; width:32px; height:16px; position:relative; -webkit-appearance:none; cursor:pointer; display:inline-block; margin:auto 10px;
    &:before { content:''; position:absolute; width:8px; height:8px; top:2px; left:2px; background:#ccc; border-radius:100%; transition:all 200ms; }
    &:checked:before { left:18px; background:#646464 }
    &:checked { border-color:#646464 }
}

a {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

b, strong { font-weight:700; }

h1 { font-size:4.0625rem; line-height:4.5625rem; font-weight:700; margin:0; text-transform:uppercase; }
h2 { font-size:3rem; line-height:3.5rem; font-weight:700; margin:0; text-transform:uppercase; }
h3 { font-size:1.75rem; line-height:2.25rem; font-weight:300; margin:0; text-transform:uppercase; }
h4 { font-size:1.25rem; line-height:1.75rem; font-weight:700; margin:0; text-transform:uppercase; }
h5 { font-size:1rem; line-height:1.5rem; font-weight:700; margin:0; }
p { font-size:1rem; line-height:1.5rem; font-style:italic; }


@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
      }
}

.overlay { position:fixed; top:0; right:0; bottom:0; left:0; background:rgba(255,255,255,.8); z-index:100;
    .overlayBox { position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); background:#fff; box-shadow:0 0 10px 0 rgba(0,0,0,.3); border-radius:6px; z-index:1; }
}
.button { width:50%; margin:auto; display:block; border-radius:20px; line-height:2rem; color:#fff; cursor:pointer; border:1px solid transparent; transition:200ms all;
    //&:not(:disabled):hover { background:#fff !important; }
    &:disabled { background:#ddd !important; cursor:not-allowed; }
}

.horizontalShadow { width:100%; height:0; position:relative; z-index:1;
    &:before { content:''; display:block; width:100%; height:15px; position:absolute; top:0; right:0; left:0; background:url(../img/shadow.png) top center no-repeat; background-size:90% 15px; }
}


#root { padding:0; height:100%; background:url(../img/background.jpg) no-repeat fixed; background-size:cover; display:flex; flex-direction:column;
    #main { flex:1; overflow:auto; width:100%; justify-content:flex-start; align-items:stretch; align-content:stretch; }
}

footer { background:rgba(255,255,255,.8); width:100%;

    .footerInner { display:flex; flex-direction:row; justify-content:space-between; padding:20px 10px; max-width:1500px; width:100%; margin:0 auto; box-sizing:border-box;
        .logoBox { display:block; height:40px; width:200px;
            .logo { height:40px; width:100%; max-width:200px; background-size:contain; background-repeat:no-repeat; background-position:left center; margin:auto 0; }
        }
        .linkBox { line-height:40px;
            span { margin:0 10px; }
            a { font-size:1rem; color:#646464; text-decoration:none; }
        }
    }
}

@media (max-width:1440px) {
    html { font-size:13px; }
}

@media (max-width:1024px) {
    html { font-size:12px; }
}

@media (max-width:800px) {
    html { font-size:11px; }
    body { overflow:auto; position:relative; }
}

@media (max-width:600px) {
    html { font-size:12px; }
}
