.healthyWorking { width:100%; height:100%; position:relative; display:flex; flex-direction:column; justify-content:flex-start; align-items:stretch; align-content:stretch; overflow:hidden; margin:0 auto; border:0;
    .forms { border:0; width:100%;
        .navigation { width:100%; max-width:1200px; margin:0 auto; display:flex;
            .logo,
            .mobilemenu { display:none; }

            .coachmenu { flex:1; display:flex; flex-direction:row; list-style-type:none; margin:0; padding:0; justify-content:center; align-items:stretch; z-index:10;
                &.coachee { display:none; }
                li { margin:0; display:block; text-align:center; line-height:4rem; position:relative; flex:1 0 auto;
                    &.space.coachee { display:none; }
                    &.results { flex:19; display:flex; justify-content:center; flex-direction:column;
                        &.coachee { display:none; }

                        .resultsText { line-height:18px; font-size:1rem; font-weight:bold; }
                        .sessionData { line-height:18px; font-size:.8rem; white-space:pre-wrap; }
                    }
                    a { line-height:20px; }
                }
            }

            ul { flex:1; display:flex; flex-direction:row; list-style-type:none; margin:0; padding:0; justify-content:center; align-items:stretch; z-index:10;
                &.normalMenu.coach { flex:0; width:40px; }

                li { margin:0; display:block; text-align:center; line-height:4rem; position:relative; flex:1 0 auto;
                    &.home {
                        span { display:none; }
                    }
                    &.link {
                        &:before { content:''; position:absolute; bottom:0; left:50%; width:0; border-bottom:3px solid #ccc; transform:translateX(-50%); transition:width 200ms; }
                    }
                    &.err {
                        a { color:#a60000; }
                    }

                    &:hover:not(.disabled),
                    &.current { font-weight:normal;
                        &:before { width:100%; }
                    }

                    &.disabled:hover a { color:#333 !important; cursor:auto; }

                    a,
                    span { display:block; padding:0 5px; text-decoration:none; color:#333; white-space:nowrap; font-family:'Merriweather Sans', serif; font-size:1rem; transition:all 200ms;
                        svg { width:20px; position:relative; top:2px; }
                    }

                    &.menu { position:relative; margin:0 0 0 10px; cursor:pointer;
                        &:hover {
                            ul { display:block; }
                        }

                        svg { height:20px; cursor:pointer; margin:0 5px; position:relative; top:4px;
                            path { transition:all 200ms; }
                        }
                        ul { display:none; position:absolute; top:90%; right:0; background:#fff; box-shadow:0 0 5px rgba(0,0,0, .5); padding:10px; border-radius:5px; z-index:100;
                            li { line-height:2.5rem; text-align:right;
                                a { cursor:pointer; }
                            }
                        }
                    }

                    &.social { padding:0 15px; }



                    //&.menu { margin-left:0; }
                }
            }
        }

        .content { flex:1 1 0; overflow-y:auto; width:100%; max-width:100%; margin:0 auto;
            .mobileLabels { display:none; }


            .sortingLabels { display:grid; grid-template-columns:1fr 1fr 1fr; width:100%; margin:10px auto 0; font-size:.8rem; padding:10px; box-sizing:border-box; max-width:700px;
                .label { display:flex; flex-direction:column; justify-content:center; position:relative; box-sizing:border-box; text-align:center; margin: auto; cursor:pointer; user-select:none;
                    &:first-child {
                        .sortBox { border-top-color:#c70000; }
                    }
                    &:nth-child(2) { cursor:auto;
                        .sortBox { margin:2px 10px 0; cursor:pointer; }
                    }
                    &:last-child {
                        .sortBox { border-top-color:#649600; }
                    }

                    .sortBox { margin:2px auto 0;
                        display:block; width:0; height:0; border-style:solid; border-width:10px 7.5px 0 7.5px; border-color:#949494 transparent transparent transparent; transition:all 200ms;

                        &.asc { transform:rotate(180deg); }
                    }

                    .sorting { height:1rem; font-size:.8rem; }

                    .sortings { display:flex; flex-direction:row; justify-content:space-between; }
                }


                //.sortBox { width:20px; height:20px; background:#333; }
            }
        }

        .footer { display:flex; flex-direction:row; justify-content:space-between; padding:20px 40px; width:100%; max-width:1200px; margin:0 auto; box-sizing:border-box;
            .balanceAktiveLogo { height:60px; width:15%; max-width:200px; background-size:contain; background-repeat:no-repeat; background-position:left center; margin:auto 0; }
            .logo { height:60px; width:15%; max-width:200px; margin:auto 0;
                span { width:100%; height:100%; display:block; background-size:contain; background-repeat:no-repeat; background-position:right center;  }
            }

            .centerBox { margin:auto; width:70%; display:flex; flex-direction:row; justify-content:center;
                .btn { background:#646464; color:#fff; text-decoration:none; line-height:2.5rem; display:inline-block; padding:0 20px 0 30px; margin:1px; border-radius:20px 0 0 20px; text-transform:uppercase; min-width:80px; text-align:center;  font-family:'Merriweather Sans', serif; transition:all 200ms; white-space:nowrap; cursor:pointer;
                    &.next { border-radius:0 20px 20px 0; padding:0 30px 0 20px; }
                    &.disabled { background:none; }
                    &.disable { background:#ccc; cursor:not-allowed; }
                    &.round { border-radius:20px; padding:0 20px; }
                }

                .download { width:240px;
                    .loading { display:block; width:188px; height:35px;
                        .spinner { height:35px;
                            div { width:5px; height:5px; background-color:#fff; border-radius:100%; display:inline-block; animation:sk-bouncedelay 1.4s infinite ease-in-out both;
                                &.bounce1 { animation-delay:-0.32s; }
                                &.bounce2 { animation-delay:-0.16s; }
                                &.bounce3 { animation-delay:-0.12s; }
                            }
                        }
                    }
                }
            }
        }

    }

}



@media (max-width:1024px) {
    .healthyWorking { width:100%; border-bottom:1px solid #ccc;
        .forms { display:flex; flex-direction:column; overflow:hidden; height:100%;
            ul { position:sticky; top:0; background:#fff; border-bottom:1px solid #ccc;}

            .navigation { height:40px; line-height:40px; display:flex; flex-direction:row; justify-content:space-between; position:relative; background:rgba(255,255,255, .6);
                .logo { display:block; width:80px; height:30px; margin:5px 10px; overflow:hidden; box-sizing:border-box;
                    span { display:block; height:30px; background-size:auto 100%; background-position:center center; background-repeat:no-repeat; }
                }

                .mobilemenu { display:block; height:30px; width:30px; cursor:pointer; margin:auto 5px; padding:5px; box-sizing:border-box; border-radius:5px; fill:#fff; }

                .coachmenu, .coachmenu.coachee { position:relative; display:flex; top:0; box-shadow:none; background:none; border:0; margin-right:50px;
                    &.coach { flex-direction:row;
                        li a { line-height:40px; }
                    }
                    li.space { display:none !important; }
                    li { display:block !important; padding:0;
                        div { line-height:20px !important; }
                    }
                }

                ul { display:none; position:absolute; top:100%; right:0; border-radius:6px; box-shadow:0 0 5px 0 rgba(0,0,0,.4); z-index:30;
                    li { padding:5px 20px; line-height:2.5rem; text-align:left;
                        &.disabled a { color:#ccc; }
                        &.home {
                            svg { display:none; }
                            span { display:block; padding:0; }
                        }

                        &.menu { padding:0; margin:0;
                            svg { display:none; }
                            ul { display:block; position:relative; border:0; padding:0; box-shadow:none; margin:0;
                                li { text-align:left; }
                            }
                        }
                    }
                }

                ul.visible { display:block; }
            }

            .content { height:100%; }

            .footer {
                .balanceAktiveLogo,
                .logo { display:none; }
            }
        }
    }
}


@media (max-width:800px) {
    .healthyWorking { width:100%; border-bottom:1px solid #ccc;
        .forms {
            .horizontalShadow { z-index:20; }
            .content {
                .mobileLabels { position:sticky; top:0; z-index:10; display:grid; grid-template-columns:1fr 1fr 1fr; width:100%; margin:0; background:#e6e6e6; font-size:.8rem; padding:10px; box-sizing:border-box;
                    .noMatch { display:flex; flex-direction:column; justify-content:center; width:100%; text-align:left; padding-left:20px; position:relative; box-sizing:border-box;
                        &:before { content:''; display:block; position:absolute; top:50%; left:0; transform:translateY(-50%); width:0; height:0; border-style:solid; border-width:7.5px 5px 0 5px; border-color: #c70000 transparent transparent transparent; }
                    }

                    .actionRequired { width:100%; text-align:center; position:relative; }

                    .match { display:flex; flex-direction:column; justify-content:center; width:100%; text-align:right; padding-right:20px; position:relative; box-sizing:border-box;
                        &:before { content:''; display:block; position:absolute; top:50%; right:0; transform:translateY(-50%); width:0; height:0; border-style:solid; border-width:7.5px 5px 0 5px; border-color: #649600 transparent transparent transparent; }
                    }

                }
                .labels { display:none; }
            }

            .footer {
                .centerBox { width:100%; }
            }
        }
    }
}
