.addUser {
    form {
        div.categories { max-height:500px; overflow:auto;
            .category {
                &__questions { max-height:0; overflow:hidden; transition:all 200ms;
                    &--enabled { height:100%; max-height:900px; overflow:auto; }
                }

                &__question { display:flex; flex-direction:row; justify-content:flex-start; align-items:stretch; align-content:stretch; padding:0 5px;

                    &__enable { margin:5px 0 auto; }

                    &__name { flex:1; border-radius:5px; text-align:left; margin:0 0 10px 10px; border:1px solid #191919; padding:10px; line-height:1.25rem;
                        &--disabled { border:1px solid #ccc; color:#ccc; }
                        &--readonly { }
                    }

                    &__edit { width:40px; text-align:center; cursor:pointer; font-size:1.5rem; line-height:2rem;
                        &--disabled { display:none; }
                    }

                    &__remove { line-height:4rem; cursor:pointer; border-radius:5px; transition:all 200ms; margin:0 0 10px 10px;
                        &:hover { background:#ccc; color:#fff; }
                    }
                }
            }
        }
    }
}

