@import "range-slider";

.healthyWorking {
    .formData { padding:10px; display:flex; flex-direction:column; box-sizing:border-box; justify-content:flex-start; align-items:stretch; align-content:stretch; height:auto; width:100%; max-width:900px; margin:0 auto;

        .subheadline { text-align:center; margin:-20px 0 20px; font-style:italic; white-space:pre-line;
            p { margin-bottom:0; }
        }

        .labels { display:flex; flex-direction:row; justify-content:flex-start; align-items:stretch; align-content:stretch; width:100%; margin:0;
            .question { width:9%; text-align:center; }
            .line { width:2%;
                &.left { border-left:2px solid #ccc; }
                &.right{ border-right:2px solid #ccc; }
            }

            .noMatch { width:26%; text-align:center; padding-bottom:60px; position:relative;
                &:before { content:''; display:block; position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); width:0; height:0; border-style:solid; border-width:10px 7.5px 0 7.5px; border-color: #c70000 transparent transparent transparent; }
            }
            .actionRequired { width:26%; text-align:center; padding-bottom:60px; position:relative;
                &:before { content:''; display:block; position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); width:0; height:0; border-style:solid; border-width:10px 7.5px 0 7.5px; border-color: #949494 transparent transparent transparent; }
            }
            .match { width:26%; text-align:center; padding-bottom:60px; position:relative;
                &:before { content:''; display:block; position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); width:0; height:0; border-style:solid; border-width:10px 7.5px 0 7.5px; border-color: #649600 transparent transparent transparent; }
            }

            .space { width:9%; }
        }

        .questions { flex:1 0 auto; display:flex; flex-direction:column; justify-content:center; }

        .question { display:flex; flex-direction:column; margin-bottom:0;
            .top { display:flex; flex-direction:row; justify-content:center; text-transform:none; font-family:'Merriweather Sans', serif; text-align:center; white-space:pre-line; line-height:1.5rem;
                .question { width:9%; text-align:center; }
                .line { width:2%;
                    &.left { border-left:2px solid #ccc; }
                    &.right{ border-right:2px solid #ccc; }
                }

                .questionText { width:78%; text-align:center;
                    .questionNumber { display:none; }
                }

                .space { width:9%; }
            }

            .slider { display:flex; flex-direction:row; justify-content:center; margin:0;

                .line { width:2%;
                    &.left { border-left:2px solid #ccc; }
                    &.right{ border-right:2px solid #ccc; }
                }

                .space { width:9%; }

                .selectionBox { width:78%; display:flex; flex-direction:row; padding:0 10%; box-sizing:border-box; justify-content:flex-start; align-items:stretch; align-content:stretch; margin:10px 0 30px;
                    &.results { display:grid; grid-template-columns:1fr 50px 50px 1fr; grid-gap:0; justify-content:center;
                        .cellDiv { border-radius:0; transition:all 200ms; cursor:auto; border:none; box-sizing:border-box;
                            &.value_-3 { background:#c70000;
                                &:hover { background:#c70000; }
                                text { fill:#fff; }
                            }
                            &.value_-2 { background:#f29200;
                                &:hover { background:#f29200; }
                                text { fill:#fff; }
                            }
                            &.value_-1 { background:#ffcc00;
                                &:hover { background:#ffcc00; }
                                text { fill:#fff; }
                            }
                            &.value_negative, &.value_positive { background:#949494;
                                &:hover { background:#949494; }
                                text { fill:#fff; }
                            }
                            &.value_1 { background:#c2d900;
                                &:hover { background:#c2d900; }
                                text { fill:#fff; }
                            }
                            &.value_2 { background:#69D230;
                                &:hover { background:#69D230; }
                                text { fill:#fff; }
                            }
                            &.value_3 { background:#649600;
                                &:hover { background:#649600; }
                                text { fill:#fff; }
                            }
                        }

                        .sub { background:#ddd; display:grid; grid-gap:0; border-radius:30px 0 0 30px; overflow:hidden; margin:0; border:none; justify-content:flex-end;

                        }
                        .add { background:#ddd; display:grid; grid-gap:0; border-radius: 0 30px 30px 0; overflow:hidden; margin:0; border:none;

                        }
                    }



                    .cellDiv { padding-top:0; width:50px; height:50px; margin:0 auto; position:relative; border-radius:100%; border:2px solid #ccc; transition:all 200ms; cursor:pointer; box-sizing:border-box;
                        .jeton { position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); width:48px; border-radius:100%; box-sizing:border-box; cursor:pointer; box-shadow:0 2px 5px rgba(0,0,0,.5); margin:auto auto; display:block; background:#fff; z-index:30; }
                        .index { position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); text-align:center; color:#ccc; pointer-events:none; z-index:1; }

                        &.notAvailable { background:#ccc; opacity:.5;
                            .index {
                                text { fill:#fff; }
                            }
                        }

                        &.background_hover_add,
                        &.background_hover:hover { background:rgba(0,0,0, .2);
                            svg text { fill:#fff; }
                        }

                        &:first-child{ border-color:#c70000;
                            &:hover, &.current { background:#c70000;
                                text { fill:#fff; }
                            }
                            text { fill:#c70000; }
                        }
                        &:nth-child(2) { border-color:#f29200;
                            &:hover, &.current { background:#f29200;
                                text { fill:#fff; }
                            }
                            text { fill:#f29200; }
                        }
                        &:nth-child(3) { border-color:#ffcc00;
                            &:hover, &.current { background:#ffcc00;
                                text { fill:#fff; }
                            }
                            text { fill:#ffcc00; }
                        }
                        &:nth-child(4) { border-color:#949494;
                            &:hover, &.current { background:#949494;
                                text { fill:#fff; }

                                &.important:not(.disabled) {
                                    border-color:#c70000;
                                    background:#c70000;

                                    path { fill:#fff; }
                                }

                                &.positive:not(.disabled) {
                                    border-color:#649600;
                                    background:#649600;

                                    path { fill:#fff; }
                                }
                            }
                            text { fill:#949494; }
                        }
                        &:nth-child(5) { border-color:#CCE400;
                            &:hover, &.current { background:#CCE400;
                                text { fill:#fff; }
                            }
                            text { fill:#CCE400; }
                        }
                        &:nth-child(6) { border-color:#69D230;
                            &:hover, &.current { background:#69D230;
                                text { fill:#fff; }
                            }
                            text { fill:#69D230; }
                        }
                        &:nth-child(7) { border-color:#649600;
                            &:hover, &.current { background:#649600;
                                text { fill:#fff; }
                            }
                            text { fill:#649600; }
                        }

                        &.disabled { border-color:#ccc; cursor:auto;
                            &:hover { background:none;
                                text { fill:#ccc; }
                            }
                            text, path { fill:#ccc; }
                        }
                    }
                }
            }
        }
        .total { text-align:right; display:flex; justify-content:center; margin-bottom:40px;
            .val { border-top:4px double #aaa; text-align:center; line-height:2rem; padding:0 20px; color:#aaa; }
        }
    }
}


@media (max-width:800px) {
    .healthyWorking {
        .formData {
            .questions {
                .question {

                    .question, .line, .space { display:none; }
                    .questionText { white-space:pre-wrap; width:100%; text-align:left; font-weight:400;
                        .questionNumber { display:inline-block; margin:0; color:#191919; font-weight:700; }
                    }

                    .slider {
                        .selectionBox { width:100%; padding:0; }
                    }
                }
            }
        }
    }
}

@media (max-width:380px) {
    .healthyWorking {
        .formData {
            .question {
                .slider {
                    .selectionBox {
                        .cellDiv { padding-top:32px; width:36px; height:36px;
                            .index {
                                path { transform:translate(4px, 4px); }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:300px) {
    .healthyWorking {
        .formData {
            .question {
                .slider {
                    .selectionBox {
                        .cellDiv { padding-top:20px; width:24px; height:24px;
                            .index {
                                width: 60%;
                                height: 60%;

                                text {scale:80%}
                                path { transform:translate(0, 0); scale: 60%; }
                            }
                        }
                    }
                }
            }
        }
    }
}
