.login { position:fixed; top:50%; left:50%; transform:translate(-50%, -50%); background:#fff; box-shadow:0 0 10px 0 rgba(0,0,0,.3); border-radius:6px; max-width:580px; width:100%;
    ul { display:flex; flex-direction:row; padding:0; margin:0; list-style-type:none;
        li { flex:1; text-align:center; line-height:3rem; color:#fff; background:#ccc; text-transform:uppercase; cursor:pointer;
            &.current { background:#fff; }
        }
    }

    form { padding:30px 40px; box-sizing:border-box;
        .balanceAktiveLogo { height:60px; width:100%; max-width:200px; background-size:contain; background-repeat:no-repeat; background-position:center center; margin:0 auto; }
        p { text-align:center; white-space:pre-line; }
        h3 { margin:0 0 20px; }

        .boxCenter,
        .box { margin:0 0 20px; display:grid; grid-template-columns:auto auto; grid-gap:10px;
            input { width:100%; border:1px solid #ccc; box-sizing:border-box; line-height:2rem; text-align:center; padding:0 10px; text-overflow:ellipsis; border-radius:20px; }
        }

        .boxCenter { margin:0 0 20px; display:block; }

        .stayLoggedInBox { margin:0 0 20px; text-align:center;
            input { margin-right:10px; }
            label { font-style:italic; }
        }

        .error { text-align:center; color:#a60000;
            p { white-space:normal; }
        }

        .bottomBox { margin:20px 0 0; display:flex; flex-direction:row; justify-content:space-between;
            button { width:40%; margin:auto; display:block; border-radius:20px; line-height:2rem; color:#fff; cursor:pointer; }

            .space { width:120px; }
            .logo { width:120px; height:40px; background-repeat:no-repeat; background-position:right center; background-size:contain; }
        }
    }
}

.logout { position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); background:#fff; box-shadow:0 0 10px 0 rgba(0,0,0,.3); border-radius:6px; overflow:hidden; padding:30px 40px; width:500px; box-sizing:border-box;
    p { text-align:center; font-style:italic; white-space:pre-line; }

    .bottomBox { margin:30px 0 0; display:flex; flex-direction:row; justify-content:space-between;
        .button { width:40%; margin:auto; display:block; border:0; border-radius:20px; line-height:2rem; color:#fff; cursor:pointer; text-align:center; }
    }
}

@media (max-width:1024px) {
    .login { max-width:400px;

    }
}

@media (max-width:600px) {
    .login { max-width:90vw;
        form {
            .box { grid-template-columns:none; grid-template-rows:auto auto; }
            .bottomBox { flex-direction:column;
                .space { display:none; }
                button { width:100%; }
                .logo { margin:20px auto 0; }
            }
        }
    }
}
