.forms { width:50%; height:100%; position:relative; display:flex; flex-direction:column; justify-content:flex-start; align-items:stretch; align-content:stretch; overflow:hidden;
    .navigation {
        .logo,
        .mobilemenu { display:none; }

        ul { display:flex; flex-direction:row; list-style-type:none; margin:0; padding:0; justify-content:center; align-items:stretch; z-index:10;
            li { margin:0; display:block; text-align:center; line-height:4rem; position:relative; flex:1 0 auto;
                &.home {
                    span { display:none; }
                }
                &.link {
                    &:before { content:''; position:absolute; bottom:0; left:50%; width:0; border-bottom:3px solid #ccc; transform:translateX(-50%); transition:width 200ms; }
                }
                &.err {
                    a { color:#a60000; }
                }

                &:hover:not(.disabled),
                &.current { font-weight:bold;
                    &:before { width:100%; }
                }

                &.disabled:hover a { color:#333 !important; cursor:auto; }

                a,
                span { display:block; padding:0 5px; text-decoration:none; color:#333; white-space:nowrap; font-family:'Merriweather Sans', serif; font-size:1rem; transition:all 200ms;
                    svg { width:20px; position:relative; top:2px; }
                }

                &.menu { position:relative; margin:0 0 0 10px; cursor:pointer;
                    &:hover {
                        ul { display:block; }
                    }

                    svg { height:20px; cursor:pointer; margin:0 5px; position:relative; top:4px;
                        path { transition:all 200ms; }
                    }
                    ul { display:none; position:absolute; top:90%; right:0; background:#fff; box-shadow:0 0 5px rgba(0,0,0, .5); padding:10px; border-radius:5px; z-index:100;
                        li { line-height:2.5rem; text-align:right;
                            a { cursor:pointer; }
                        }
                    }
                }

                &.social { padding:0 15px; }
            }
        }
    }

    .content { flex:1 1 0; overflow-y:auto; }

    .footer { display:flex; flex-direction:row; justify-content:space-between; padding:20px 40px;
        .balanceAktiveLogo { height:60px; width:15%; max-width:200px; background-size:contain; background-repeat:no-repeat; background-position:left center; margin:auto 0; }
        .logo { width:15%; height:60px; margin:0;
            a { display:block; margin:auto 0 auto auto; box-sizing:border-box; height:60px;
                img { max-height:100%; display:block;  background-repeat:no-repeat; background-position:right center; margin-left:auto; }
            }
        }

        .centerBox { margin:auto; display:flex; flex-direction:row; justify-content:center;
            .btn { background:#646464; color:#fff; text-decoration:none; line-height:2.5rem; display:inline-block; padding:0 20px 0 30px; margin:1px; border-radius:20px 0 0 20px; text-transform:uppercase; min-width:80px; text-align:center;  font-family:'Merriweather Sans', serif; transition:all 200ms; white-space:nowrap; cursor:pointer;
                &.next { border-radius:0 20px 20px 0; padding:0 30px 0 20px; }
                &.disabled { background:none; }
                &.disable { background:#ccc; cursor:not-allowed; }
            }
        }
    }

}

@media (max-width:1280px) {
    .forms {
        ul {
            .boardswitch { display:none !important; }
        }
    }
}



@media (max-width:1024px) {
    .forms { width:100%; border-bottom:1px solid #ccc;
        ul { position:sticky; top:0; background:#fff; border-bottom:1px solid #ccc;}

        .navigation { height:40px; line-height:40px; display:flex; flex-direction:row; justify-content:space-between; position:relative; background:rgba(255,255,255, .6);
            .logo { display:block; width:80px; height:30px; margin:5px 10px; overflow:hidden; box-sizing:border-box;
                span { display:block; height:30px; background-size:auto 100%; background-position:center center; background-repeat:no-repeat; }
            }

            .mobilemenu { display:block; height:30px; width:30px; cursor:pointer; margin:auto 5px; padding:5px; box-sizing:border-box; border-radius:5px; fill:#fff; }

            ul { display:none; position:absolute; top:100%; right:0; border-radius:6px; box-shadow:0 0 5px 0 rgba(0,0,0,.4);
                li { padding:5px 20px; line-height:2.5rem; text-align:left;
                    &.disabled a { color:#ccc; }
                    &.home {
                        svg { display:none; }
                        span { display:block; padding:0; }
                    }

                    &.menu { padding:0; margin:0;
                        svg { display:none; }
                        ul { display:block; position:relative; border:0; padding:0; box-shadow:none; margin:0;
                            li { text-align:left; }
                        }
                    }
                }
            }

            ul.visible { display:block; }
        }

        .footer {
            .balanceAktiveLogo,
            .logo { display:none; }
        }
    }
}
