#imprint { padding:20px 40px; max-width:1080px; width:100%; margin:0 auto; background:rgba(255,255,255,.9); box-sizing:border-box;
    .section { text-align:center; font-size:2rem; margin:0 auto 40px; padding:5px 0; border-bottom:2px solid #ccc; text-transform:uppercase; width:50%; }

    .imprint { display:flex; flex-direction:row;
        section { flex:1; padding:0 15px; line-height:1.5rem; white-space:pre-line;
            a { color:#191919; }
            p { margin:0; }
            hr { margin:0; }
        }
    }

}
